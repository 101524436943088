import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '@ltic/ngx-core/common';

import { getEnvironment } from 'src/app/environment';

export abstract class RtQueueApiBase {
  protected rtApiUrl = `${getEnvironment().lticProxy}RT_QUEUE/`;

  constructor(protected userService: UserService) {}

  protected authorizationHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    return headers.append('Authorization', 'Bearer ' + this.userService.getToken());
  }
}
