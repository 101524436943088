import { Component, EventEmitter, Output } from '@angular/core';
import { AuthService } from '@ltic/ngx-core/auth';
import { UserService } from '@ltic/ngx-core/common';

@Component({
  selector: 'lgx-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Output() toggleSidebar = new EventEmitter<void>();
  user: any;

  constructor(private authService: AuthService, private userService: UserService) {
    this.user = userService.getUserProfile();
  }

  logOut(): void {
    this.authService.logout();
  }
}
