import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageScrollConfig } from 'ngx-page-scroll';
import { getEnvironment } from './environment';

declare var jQuery: any;

@Component({
  selector: 'kn-portal',
  styleUrls: ['../scss/application.scss'],
  template: `<router-outlet></router-outlet>`,
  encapsulation: ViewEncapsulation.None,
})
export class App implements OnInit {
  constructor() {
    PageScrollConfig.defaultDuration = 500;
    PageScrollConfig.defaultInterruptible = false;
    PageScrollConfig.defaultEasingLogic = {
      ease: (t: number, b: number, c: number, d: number): number => {
        // easeInOutExpo easing
        if (t === 0) return b;
        if (t === d) return b + c;
        if ((t /= d / 2) < 1) return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
        return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
      },
    };
  }
  ngOnInit(): void {
    //Temporary solution until CCC resolves issue on servers side
    this.redirectHttpToHttps();
  }

  private redirectHttpToHttps() {
    if (getEnvironment().ENV === 'dev' || getEnvironment().ENV === 'prod') {
      if (window.location.protocol === 'http:') {
        const url = window.location.href.replace('http', 'https');
        window.location.replace(url);
      }
    }
  }
}
