import { PagesRoutes } from 'src/app/modules/pages.routes';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, provideRoutes } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { BsDropdownConfig, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';

import { LayoutComponent } from './layout.component';
import { SupportButtonComponent } from './support-button/support-button.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { NavbarComponent } from './navbar/navbar.component';

@NgModule({
  imports: [CommonModule, RouterModule, FontAwesomeModule, BsDropdownModule.forRoot(), BrowserAnimationsModule, ModalModule],
  declarations: [LayoutComponent, SupportButtonComponent, SidebarMenuComponent, NavbarComponent],
  providers: [provideRoutes(PagesRoutes), { provide: BsDropdownConfig, useValue: { autoClose: true } }, BsModalService],
  exports: [LayoutComponent],
})
export class LayoutModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
