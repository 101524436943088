import { Component, ViewEncapsulation } from '@angular/core';
import { UserService } from '@ltic/ngx-core/common';

interface AppDefinition {
  title: string;
  image: string;
  path: string;
  requires?: string;
}

const APPS: AppDefinition[] = [
  {
    title: 'NEPA',
    image: '/assets/images/lra.png',
    path: '/app/lra',
    requires: 'kn:lra',
  },
  {
    title: 'KN Disruptions',
    image: '/assets/images/diss.png',
    path: '/app/disruption',
    requires: 'kn:disruption:disruption:app',
  },
  {
    title: 'KN Disruptions (new)',
    image: '/assets/images/diss.png',
    path: '/app/disruptions',
    requires: 'kn:disruptions:disruptions:app',
  },
  {
    title: 'SE Disruptions',
    image: '/assets/images/diss.png',
    path: '/app/se_disruption',
    requires: 'kn:disruption:se_disruption:app',
  },
  {
    title: 'IL Disruptions',
    image: '/assets/images/diss.png',
    path: '/app/il_disruption',
    requires: 'kn:disruption:il_disruption:app',
  },
  {
    title: 'HyperCare Disruptions',
    image: '/assets/images/diss.png',
    path: '/app/hypercare_disruption',
    requires: 'kn:disruption:hypercare:app',
  },
  {
    title: 'Capa Disruptions',
    image: '/assets/images/diss.png',
    path: '/app/capa_analysis',
    requires: 'kn:disruption:aircapa:app',
  },
  {
    title: 'CoVaxMon',
    image: '/assets/images/covaxmon.png',
    path: '/app/covaxmon',
    requires: 'kn:covaxmon',
  },
  {
    title: 'Equipment shortages',
    image: '/assets/images/eq_shortages.png',
    path: '/app/eq_shortages',
    requires: 'kn:eq_shortages',
  },
  {
    title: 'Weather Map',
    image: '/assets/images/weather_map.png',
    path: '/app/weather_map',
    requires: 'kn:weather_map',
  },
  {
    title: 'DataWiz',
    image: '/assets/images/datawiz.png',
    path: '/app/datawiz',
    requires: 'kn:capawiz',
  },
  {
    title: 'AirExplorer',
    image: '/assets/images/flight_schedules.png',
    path: '/app/flight_schedules',
    requires: 'kn:flight_schedules',
  },
  {
    title: 'Transit Time Scheduler',
    image: '/assets/images/transit_time_scheduler.png',
    path: '/app/transit_time_scheduler',
    requires: 'kn:transit_time_scheduler',
  },
  {
    title: 'HyperCare Cockpit',
    image: '/assets/images/hypercare.png',
    path: '/app/hypercare',
    requires: 'kn:hypercare',
  },
  {
    title: 'Flight Matrix Generator',
    image: '/assets/images/flight_matrix.png',
    path: '/app/flight_matrix',
    requires: 'kn:flight_matrix',
  },
  {
    title: 'ARE Blending',
    image: '/assets/images/blendare.png',
    path: '/app/blendare',
    requires: 'kn:blendare',
  },
]

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent {
  tiles: AppDefinition[] = [];

  constructor(private userService: UserService) {
    this.tiles = APPS.filter(app => this.userService.isAuthorized(app));
  }
}
