import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RtQueueAuthService } from './rt-queue-auth.service';

@Injectable()
export class RtQueueAuthGuard implements CanActivate {
  constructor(private authService: RtQueueAuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<true | UrlTree> {
    return this.authService.isLoggedIn().pipe(
      map((isLoggedIn) => {
        if (!isLoggedIn) {
          this.authService.originUrl = state.url;
          return this.router.parseUrl(RtQueueAuthService.loginFormUrl);
        }
        return true;
      })
    );
  }
}
