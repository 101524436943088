import { Injectable } from '@angular/core';

import { RtItem } from './rt-queue-parser.service';
import { TicketItem } from '../models/rt.model';
import { parse } from 'date-fns';

@Injectable()
export class RtQueueMapperService {
  mapTickets(tickets: RtItem[]): TicketItem[] {
    return tickets
      .filter((ticket) => !('noMatchingResults' in ticket))
      .map((ticket) => this.mapTicket(ticket));
  }

  mapTicket(ticket: RtItem): TicketItem {
    let dueDate;

    if (ticket.due !== 'Not set') {
      const [weekDay, month, day, time, year] = (ticket.due as string).split(' ');
      dueDate = new Date(`${year}-${month}-${day} ${time}Z`).valueOf()
      dueDate = parse(`${day} ${month} ${year} ${time} Z`, 'dd MMM yyyy HH:mm:ss X', new Date()).valueOf();
    }

    return {
      id: (ticket.id as string).split('/')[1],
      due: dueDate,
      status: ticket.status,
      subject: ticket.subject,
      owner: ticket.owner,
      queue: ticket.queue,
      cfCustomer: ticket.cfCustomer,
      cfDeviationType: ticket.cfDeviationType,
      cfAlarmValidity: ticket.cfAlarmValidity,
      cfActionTaken: ticket.cfActionTaken,
      cfReasonCode: ticket.cfReasonCode,
      cfCovidShipment: ticket.cfCovidShipment,
      cfTrackingNumber: ticket.cfTrackingNumber,
      cfControllingStation: ticket.cfControllingStation,
      cfContainerDetails: ticket.cfContainerDetails,
      cfModeOfTransport: ticket.cfModeOfTransport,
      cfTemperatureRange: ticket.cfTemperatureRange,
      cfCustomerReference: ticket.cfCustomerReference,
    } as TicketItem;
  }
}
