import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { UserService } from '@ltic/ngx-core/common';
import { RtQueueApiBase } from './rt-queue-api.base';
import { RtContentStructure, RtQueueResponse } from './rt-queue-parser.service';
import { LoginData } from '../models/rt.model';
import { RtQueueParserService } from './rt-queue-parser.service';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService, ModuleStoragePrefix } from 'src/app/services/local-storage.service';

@Injectable()
export class RtQueueAuthService extends RtQueueApiBase {
  static defaultOriginUrl = '/app/hypercare';
  static loginFormUrl = '/app/hypercare/login';
  private static STORAGE_USER_KEY = 'user';

  originUrl: string = RtQueueAuthService.defaultOriginUrl;
  private loggedInSubject = new ReplaySubject<boolean>(1);

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    userService: UserService,
    private parserService: RtQueueParserService,
    private storageService: LocalStorageService
  ) {
    super(userService);
    this.checkIfLoggedIn();
  }

  isLoggedIn(): Observable<boolean> {
    return this.loggedInSubject.asObservable();
  }

  login(login: string, password: string): Observable<boolean> {
    const params = {
      user: login,
      pass: password,
    };

    const headers = this.authorizationHeaders();

    return this.httpClient.get(this.rtApiUrl, { params, headers, responseType: 'text', withCredentials: true }).pipe(
      catchError((error) => {
        console.error(error);
        return throwError(Error('Login failed'));
      }),
      map((rawResponse) => this.parserService.parseResponse(rawResponse, RtContentStructure.SIMPLE_MESSAGE)),
      map((response) => this.handleResponse(response, login))
    );
  }

  redirectToLoginForm(): void {
    this.loggedInSubject.next(false);
    this.router.navigate([RtQueueAuthService.loginFormUrl]);
  }

  redirectToOriginUrl(): void {
    const redirectUrl = this.originUrl;
    this.originUrl = RtQueueAuthService.defaultOriginUrl;
    this.router.navigate([redirectUrl]);
  }

  getUsername(): string {
    return this.storageService.getData(ModuleStoragePrefix.RT_QUEUE, RtQueueAuthService.STORAGE_USER_KEY);
  }

  private saveUsername(user: string): void {
    this.storageService.storeData<string>(ModuleStoragePrefix.RT_QUEUE, RtQueueAuthService.STORAGE_USER_KEY, user);
  }

  private removeUsername(): void {
    this.storageService.removeData(ModuleStoragePrefix.RT_QUEUE, RtQueueAuthService.STORAGE_USER_KEY);
  }

  private checkIfLoggedIn(): void {
    const user = this.getUsername();
    if (user) {
      // RT Queue Authentication is based on cookie which we cannot access and validate, BUT we can try to login in with any credentials.
      // We will get 200 response in case the cookie is valid and 401 response otherwise.
      this.login(user, '').subscribe(
        () => {
          console.log('Already logged in to RT Queue');
          this.loggedInSubject.next(true);
        },
        (error) => {
          // ignore error
          this.loggedInSubject.next(false);
        }
      );
    } else {
      this.loggedInSubject.next(false);
    }
  }

  private handleResponse(response: RtQueueResponse, user: string): boolean {
    const { data, status } = response;

    if (status.code === 200) {
      this.saveUsername(user);
      this.loggedInSubject.next(true);
      return true;
    }

    this.removeUsername();
    if (status.code === 401) {
      throw Error((data as LoginData).message);
    } else {
      throw Error('Login failed');
    }
  }
}
