import { Component, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { getEnvironment } from 'src/app/environment';

@Component({
  selector: 'support-button',
  templateUrl: './support-button.component.html',
  styleUrls: ['./support-button.component.scss']
})
export class SupportButtonComponent {
  modalRef?: BsModalRef;
  supportEmail: string;

  constructor(private modalService: BsModalService) {
    this.supportEmail = getEnvironment().supportEmail;
  }

  openSupportModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }
}
