import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { UserService } from '@ltic/ngx-core/common';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [DashboardComponent],
    providers: [UserService],
    exports: [DashboardComponent]
})
export class DashboardModule {}
