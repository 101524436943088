import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { getEnvironment } from './app/environment';

Sentry.init({
  dsn: getEnvironment().sentryDsn,
  environment: getEnvironment().ENV,
  release: 'kn-tyche-web:' + getEnvironment().buildId,
  tracesSampler: samplingContext => {
    if (samplingContext.location?.hash == '#/app/transit_time_scheduler'
      || samplingContext.transactionContext?.name == '/app/transit_time_scheduler'
      || samplingContext.transactionContext?.op == 'TransitTimesExcelLoadingMetric') {
      return 1.0;
    } else {
      return getEnvironment().production ? 0.5 : 0.1;
    }
  },
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['localhost', /^\//],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
});

if (getEnvironment().production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
