import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { RtLoginFormComponent } from './components/rt-login-form/rt-login-form.component';
import { RtQueueAuthService } from './services/rt-queue-auth.service';
import { RtQueueAuthGuard } from './services/rt-queue-auth-guard.service';
import { RtQueueApiService } from './services/rt-queue-api.service';
import { RtQueueParserService } from './services/rt-queue-parser.service';
import { RtQueueMapperService } from './services/rt-queue-mapper.service';

const primengModules = [
  InputTextModule,
  PasswordModule,
  ButtonModule,
  ProgressSpinnerModule,
  DialogModule,
  TooltipModule,
];

@NgModule({
  declarations: [RtLoginFormComponent],
  imports: [CommonModule, FormsModule, FontAwesomeModule, ...primengModules],
  providers: [
    RtQueueAuthGuard,
    RtQueueAuthService,
    RtQueueApiService,
    RtQueueParserService,
    RtQueueMapperService,
    LocalStorageService,
  ],
  exports: [RtLoginFormComponent],
})
export class RtQueueModule {}
