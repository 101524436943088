import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '@ltic/ngx-core/common';

import { RtQueueApiBase } from './rt-queue-api.base';
import { RtQueueAuthService } from './rt-queue-auth.service';
import { RtQueueMapperService } from './rt-queue-mapper.service';
import {
  RtContentStructure,
  RtData,
  RtItem,
  RtQueueParserService,
  RtQueueResponse,
  RtTicketOperation,
  UpdateRtTicket,
  UpdateRtTicketTrackingNumber,
} from './rt-queue-parser.service';
import { HistoryDetails, HistoryItem, TicketHistory, TicketItem, TicketRequestParams } from '../models/rt.model';

export const RT_DATE_FORMAT = 'ddd MMM DD HH:mm:ss YYYY';

enum TicketAction {
  EDIT = 'edit',
  TAKE = 'take',
  COMMENT = 'comment',
}

export enum TicketQueue {
  CARETEAM_CONTROLANT = "CareTeam_controlant",
  IOT_AIR_ALARM  = "iot_air_alarm",
  IOT_SEA_ALARM  = "iot_sea_alarm",
  IOT_ROAD_ALARM = "iot_road_alarm"
}

const TICKET_QUEUES = [
  TicketQueue.CARETEAM_CONTROLANT,
  TicketQueue.IOT_AIR_ALARM,
  TicketQueue.IOT_SEA_ALARM,
  TicketQueue.IOT_ROAD_ALARM
]

@Injectable()
export class RtQueueApiService extends RtQueueApiBase {
  constructor(
    private httpClient: HttpClient,
    userService: UserService,
    private parserService: RtQueueParserService,
    private mapperService: RtQueueMapperService,
    private authService: RtQueueAuthService
  ) {
    super(userService);
  }

  getActiveTickets(queues: string[]): Observable<TicketItem[]> {
    const queueExpr = this.createQueryQueueExpression(queues);
    const params = {
      query: `${queueExpr} AND ( Status = 'new' OR Status = 'open' OR Status = 'pending' OR Status = 'waiting')`,
      fields: 'Queue,Subject,Status,Created,Due,Owner,CF.{Tracking number},CF.{Mode of Transport}',
      format: 'l',
    };
    return this.getTickets(params);
  }

  getShipmentTickets(trackingNumber): Observable<TicketItem[]> {
    const queueExpr = this.createQueryQueueExpression(TICKET_QUEUES);
    const params = {
      query: `${queueExpr} AND ( 'CF.{Tracking number}' = '${trackingNumber}')`,
      fields: 'Subject,Status,Created,Due,Owner,CF.{Tracking number},CF.{Mode of Transport}',
      format: 'l',
    };
    return this.getTickets(params);
  }

  getUserName(): string {
    return this.authService.getUsername();
  }

  getTicket(ticketId: string): Observable<TicketItem> {
    const params = {
      format: 'i',
    };

    return this.getData<RtItem[]>(
      `${this.rtApiUrl}/ticket/${ticketId}/show`,
      params,
      RtContentStructure.MULTILINE_ITEMS
    ).pipe(
      map((tickets) =>
        tickets != null && tickets.length == 1 && Object.keys(tickets[0]).length > 0
          ? this.mapperService.mapTicket(tickets[0])
          : null
      )
    );
  }

  getTicketHistory(ticketId: string): Observable<TicketHistory> {
    const params = {
      format: 'i',
    };

    return this.getData<HistoryItem[]>(
      `${this.rtApiUrl}/ticket/${ticketId}/history`,
      params,
      RtContentStructure.SINGLE_LINE_ITEMS
    ).pipe(
      map((response) => ({
        ticketId: ticketId,
        history: response,
      }))
    );
  }

  getTicketHistoryEntry(ticketId: string, historyId: string): Observable<HistoryDetails> {
    return this.getData<HistoryDetails[]>(
      `${this.rtApiUrl}ticket/${ticketId}/history/id/${historyId}`,
      {},
      RtContentStructure.MULTILINE_ITEMS
    ).pipe(map((hd) => _.head(hd)));
  }

  changeStatus(ticketId: string, status: string, due?: string): Observable<boolean> {
    if (due != null) return this.updateTicket(ticketId, { Status: status, Due: due }, TicketAction.EDIT);
    else return this.updateTicket(ticketId, { Status: status }, TicketAction.EDIT);
  }

  changeTime(ticketId: string, due: string): Observable<boolean> {
    return this.updateTicket(ticketId, { Due: due }, TicketAction.EDIT);
  }

  editTicket(ticketId: string, rtItem: UpdateRtTicket): Observable<boolean> {
    return this.updateTicket(ticketId, rtItem, TicketAction.EDIT);
  }

  changeTrackingNumber(ticketId: string, rtItem: UpdateRtTicketTrackingNumber): Observable<boolean> {
    return this.updateTicket(ticketId, rtItem, TicketAction.EDIT);
  }

  stealTicket(ticketId: string): Observable<boolean> {
    return this.updateTicket(ticketId, { Ticket: ticketId, Action: 'steal' }, TicketAction.TAKE);
  }

  takeTicket(ticketId: string): Observable<boolean> {
    return this.updateTicket(ticketId, { Owner: this.authService.getUsername() }, TicketAction.EDIT);
  }

  commentTicket(ticketId: string, text: string): Observable<any> {
    return this.updateTicket(ticketId, { Action: 'comment', Text: text }, TicketAction.COMMENT);
  }

  replyTicket(ticketId: string, text: string): Observable<any> {
    return this.updateTicket(ticketId, { Action: 'correspond', Text: text }, TicketAction.COMMENT);
  }

  private getTickets(params: TicketRequestParams): Observable<TicketItem[]> {
    return this.getData<RtItem[]>(`${this.rtApiUrl}search/ticket`, params, RtContentStructure.MULTILINE_ITEMS).pipe(
      map((tickets) => this.mapperService.mapTickets(tickets))
    );
  }

  private createQueryQueueExpression(queues: string[]): string {
    if (queues?.length > 0) {
      return '(' + queues.map(queue => `Queue='${queue}'`).join(' OR ') + ')';
    } else {
      throw new Error("Illegal queue argument. No queue passed");
    }
  }

  private getData<T extends RtData>(url, params, contentStructure?: RtContentStructure): Observable<T> {
    const headers = this.authorizationHeaders();
    return this.httpClient.get(url, { params, headers, responseType: 'text', withCredentials: true }).pipe(
      map((rawResponse) => this.parserService.parseResponse(rawResponse, contentStructure)),
      map((response) => this.handleResponse(response) as T)
    );
  }

  private handleResponse(response: RtQueueResponse): RtData {
    if (response.status.code === 200) {
      return response.data;
    } else if (response.status.code === 401) {
      this.authService.redirectToLoginForm();
      throw Error('Access denied');
    } else {
      throw Error(`Unexpected error from RT Queue API with status ${response.status.code} ${response.status.text}.`);
    }
  }

  private updateTicket(ticketId: string, content: RtTicketOperation, action: TicketAction): Observable<boolean> {
    let headers = this.authorizationHeaders();
    headers = headers.append('content-type', 'application/x-www-form-urlencoded');

    // Body has to be url-encoded,
    // otherwise the browser would alter new line characters and the server would not be able to correctly parse the data.
    const body = new URLSearchParams();
    const rawContent = _.toPairs(content)
      .filter(([_, value]) => value != null)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
    body.append('content', rawContent);

    return this.httpClient
      .post(`${this.rtApiUrl}ticket/${ticketId}/${action}`, body, {
        headers,
        responseType: 'text',
        withCredentials: true,
      })
      .pipe(
        map((rawResponse) => this.parserService.parseResponse(rawResponse, RtContentStructure.SIMPLE_MESSAGE)),
        map((response) => {
          if (response.status.code === 200) return true;
          else if (response.status.code === 401) this.authService.redirectToLoginForm();

          return false;
        })
      );
  }
}
