import { Component } from '@angular/core';

@Component({
  selector: 'lgx-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  sidebarExpanded = false;

  toggleSidebar(): void {
    this.sidebarExpanded = !this.sidebarExpanded;
  }
}
