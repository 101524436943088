import { Injectable } from '@angular/core';
import _ from 'lodash';

export enum ModuleStoragePrefix {
  CAPAWIZ = 'capawiz/',
  RT_QUEUE = 'rt_queue/',
}

interface LocalStorageItem<T> {
  key: string;
  item: T;
}

@Injectable()
export class LocalStorageService {
  storeData<T>(modulePrefix: ModuleStoragePrefix, dataKey, data: T): void {
    const storageKey = this.createStorageKey(modulePrefix, dataKey);
    localStorage.setItem(storageKey, JSON.stringify(data));
  }

  removeData(modulePrefix: ModuleStoragePrefix, dataKey): void {
    const storageKey = this.createStorageKey(modulePrefix, dataKey);
    localStorage.removeItem(storageKey);
  }

  getData<T>(modulePrefix: ModuleStoragePrefix, dataKey: string, errorMsg?: string): T {
    const storageKey = this.createStorageKey(modulePrefix, dataKey);

    try {
      return JSON.parse(localStorage.getItem(storageKey));
    } catch (e) {
      if (errorMsg) console.warn(errorMsg);
    }
  }

  getItemsWithPrefix<T>(modulePrefix: ModuleStoragePrefix, dataPrefix: string): LocalStorageItem<T>[] {
    const storagePrefix = this.createStorageKey(modulePrefix, dataPrefix);
    return _.keys(localStorage)
      .filter((key) => key.startsWith(storagePrefix))
      .map((key) => {
        try {
          return {
            key: key.slice(modulePrefix.length),
            item: JSON.parse(localStorage.getItem(key)) as T,
          };
        } catch (e) {}
      })
      .filter((item) => item);
  }

  private createStorageKey(modulePrefix: ModuleStoragePrefix, dataKey: string): string {
    return `${modulePrefix}${dataKey}`;
  }
}
