import { Component, Input } from '@angular/core';
import { Route, Router } from '@angular/router';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { UserService } from '@ltic/ngx-core/common';

interface SidebarLink {
  title: string;
  icon: IconDefinition;
  requires: string;
  url?: string;
  notes?: string;
  links?: Link[];
}

interface Link {
  url: string;
  title: string;
}

@Component({
  selector: 'lgx-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent {
  @Input() sidebarExpanded = false;
  links: SidebarLink[];

  private routesByUrl: { [url: string]: Route } = {};

  constructor(private router: Router, private userService: UserService) {
    this.indexRoutes(router.config);
    this.links = this.sidebarLinks();
  }

  private indexRoutes(routes: Route[], prefix?: string) {
    routes.forEach(route => {
      let path = route.path;
      if (path.includes(':')) {
        path = path.replace(/:[^\/]*/g, ':');
      }
      const routePath = `${prefix || ''}/${path}`;
      this.routesByUrl[routePath] = route;
      if (route.children && route.children.length !== 0) {
        this.indexRoutes(route.children, routePath);
      }
    });
  }

  private sidebarLinks(): any[] {
    let sidebarLinks: any[] = [];
    Object.values(this.routesByUrl).forEach(route => {
      let sidebarLinkDefinitions = route.data && (route.data['sidebar'] instanceof Array ? route.data['sidebar'] : [route.data['sidebar']]);
      if (sidebarLinkDefinitions && sidebarLinkDefinitions.length > 0) {
        sidebarLinkDefinitions.forEach(sidebarLink => {
          if (sidebarLink && this.userService.isAuthorized(sidebarLink)) {
            if (sidebarLink.links) {
              sidebarLink.links = sidebarLink.links.filter(link => this.userService.isAuthorized(link));
            }
            sidebarLink.url = sidebarLink.url || this.getComponentUrl(route.component);
            sidebarLink.title = sidebarLink.title || route.data['name'];
            sidebarLinks.push(sidebarLink);
          }
        });
      }
    });
    return sidebarLinks;
  }

  private getComponentUrl(component): string {
    for (let url in this.routesByUrl) {
      if (this.routesByUrl[url].component === component) {
        return url;
      }
    }
  }

}
