import { Component, ViewEncapsulation, HostBinding } from '@angular/core';

@Component({
    templateUrl: './error-page.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
    @HostBinding('class') get class() {
        return 'error-page app';
    }
}
