import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { ROUTES } from './app.routes';
import { App } from './app';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { AppState } from './app.service';
import { LayoutModule } from './modules/layout/layout.module';
import { APP_MODULES } from './modules';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpClientModule } from '@angular/common/http';
import { CoreComponentsModule } from '@ltic/ngx-core/common';
import { AUTH_ROUTES, AuthModule } from '@ltic/ngx-core/auth';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ENV_PROVIDERS, getEnvironment } from './environment';
import * as Sentry from '@sentry/angular';
import { EmptyRouteComponent } from './components/empty-route/empty-route.component';
// Application wide providers
const APP_PROVIDERS = [AppState];

@NgModule({
  bootstrap: [App],
  declarations: [App, ErrorPageComponent, EmptyRouteComponent],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    CoreComponentsModule.forRoot(getEnvironment()),
    ...APP_MODULES,
    HttpClientModule,
    AuthModule.forRoot(getEnvironment()),
    RouterModule.forRoot([...AUTH_ROUTES, ...ROUTES], {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
    LayoutModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument(),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({}),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    // expose our Services and Providers into Angular's dependency injection
    ...APP_PROVIDERS,
    ...ENV_PROVIDERS,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [RouterModule],
})
export class AppModule {
}
