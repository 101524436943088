import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { getEnvironment } from '../environment';

@Injectable({ providedIn: 'root' })
export class TycheRedirectGuard implements CanActivate {
	readonly tycheWebRootUrl = getEnvironment().tycheWebRootUrl;

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const redirectUrl = `${this.tycheWebRootUrl}/${route.data['tycheWebRootPath']}`;
		setTimeout(() => window.location.replace(redirectUrl))
		return true;
	}
}
