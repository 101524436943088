import { Component } from '@angular/core';
import { RtQueueAuthService } from '../../services/rt-queue-auth.service';

@Component({
  selector: 'rt-login-form',
  templateUrl: './rt-login-form.component.html',
  styleUrls: ['./rt-login-form.component.scss'],
})
export class RtLoginFormComponent {
  login: string = '';
  password: string = '';
  isLoggingIn: boolean = false;
  loginErrorMsg: string = '';

  constructor(private authService: RtQueueAuthService) {}

  loginToRt() {
    this.isLoggingIn = true;
    this.loginErrorMsg = '';

    this.authService.login(this.login, this.password).subscribe(
      () => {
        console.log('Logged in to RT Queue');
        this.isLoggingIn = false;
        this.authService.redirectToOriginUrl();
      },
      (error) => {
        this.loginErrorMsg = error.message;
        this.isLoggingIn = false;
      }
    );
  }
}
