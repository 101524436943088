
// Angular 2
// rc2 workaround
import { enableDebugTools, disableDebugTools } from '@angular/platform-browser';
import { enableProdMode, ApplicationRef, ErrorHandler } from '@angular/core';
// import { GlobalErrorLogger } from 'ngx-webcore';
// Environment Providers
let PROVIDERS: any[] = [
    // common env directives
    // { provide: ErrorHandler, useClass: GlobalErrorLogger },
];

// Angular debug tools in the dev console
// https://github.com/angular/angular/blob/86405345b781a9dc2438c0fbe3e9409245647019/TOOLS_JS.md
let _decorateModuleRef = function identity<T>(value: T): T { return value; };

if (getEnvironment().ENV === 'prod') {
    // Production
    enableProdMode();
    _decorateModuleRef = (modRef: any) => {
        disableDebugTools();

        return modRef;
    };
    PROVIDERS = [
        ...PROVIDERS,
        // custom providers in production
    ];

} else {

    _decorateModuleRef = (modRef: any) => {
        const appRef = modRef.injector.get(ApplicationRef);
        const cmpRef = appRef.components[0];

        let _ng = (<any>window).ng;
        enableDebugTools(cmpRef);
        (<any>window).ng.probe = _ng.probe;
        (<any>window).ng.coreTokens = _ng.coreTokens;
        return modRef;
    };

    // Development
    PROVIDERS = [
        ...PROVIDERS,
        // custom providers in development
    ];

}

export const decorateModuleRef = _decorateModuleRef;

export const ENV_PROVIDERS = [
    ...PROVIDERS
];

export function getEnvironment() {
  return window['__env'];
}
