import { Injectable } from '@angular/core';
import _ from 'lodash';

interface RtHeader {
  apiVersion: string;
  status: {
    code: number;
    text: string;
  };
}

type RtItemValue = string | string[];

export type RtItem = { [key: string]: RtItemValue };
export type RtData = RtItem | RtItem[];

export interface RtQueueResponse extends RtHeader {
  data: RtData;
}

export enum RtContentStructure {
  SIMPLE_MESSAGE,
  SINGLE_LINE_ITEMS,
  MULTILINE_ITEMS,
}

export type RtTicketOperation =
  | UpdateRtTicket
  | CommentRtTicket
  | UpdateRtTicketOwner
  | UpdateRtTicketStatus
  | UpdateRtTicketDue
  | UpdateRtTicketTrackingNumber
  | StealRtTicket;

export interface UpdateRtTicket {
  'CF-Customer': string;
  'CF-deviation type': string;
  'CF-Tracking number': string;
  'CF-Mode of Transport': string;
  'CF-controlling station': string;
  'CF-Export Country Code': string;
  'CF-*Product': string;
  'CF-Lane': string;
  'CF-Gross Weight': string;
  'CF-Chargeable Weight': string;
  'CF-Number of Pcs': string;
  'CF-MAWB/BL': string;
  'CF-Carrier': string;
  'CF-Covid Shipment': string;
  'CF-Alarm Validity': string;
  'CF-Reason Code': string;
  'CF-action taken': string;
  'CF-EMO': string;
  'CF-IMO': string;
  'CF-container details': string;
  'CF-Temperature Range': string;
  'CF-Customer Reference': string;
}

export interface StealRtTicket {
  Ticket: string;
  Action: string;
}

export interface CommentRtTicket {
  Action: string;
  Text: string;
}

export interface UpdateRtTicketTrackingNumber {
  'CF-Tracking number': string;
}

export interface UpdateRtTicketOwner {
  Owner: string;
}

export interface UpdateRtTicketStatus {
  Status: string;
}

export interface UpdateRtTicketDue {
  Due: string;
}

@Injectable()
export class RtQueueParserService {
  parseResponse(response: string, contentStructure = RtContentStructure.MULTILINE_ITEMS): RtQueueResponse {
    const [rawHeader, rawContent] = this.getHeaderAndContent(response);
    const header = this.parseHeader(rawHeader);
    const items = this.parseContent(rawContent, contentStructure);

    const result = {
      ...header,
      data: items,
    };

    return result;
  }

  private getHeaderAndContent(rawResponse: string): [string, string] {
    const firstLineEnd = rawResponse.indexOf('\n');
    const rawHeader = rawResponse.slice(0, firstLineEnd);
    const rawContent = rawResponse.slice(firstLineEnd + 1);

    return [rawHeader, rawContent];
  }

  private parseHeader(rawHeader): RtHeader {
    const [apiVersion, statusCode, statusText] = rawHeader.split(' ');

    return {
      apiVersion,
      status: {
        code: Number(statusCode),
        text: statusText,
      },
    };
  }

  private parseContent(rawContent: string, contentStructure: RtContentStructure): RtData {
    switch (contentStructure) {
      case RtContentStructure.SIMPLE_MESSAGE:
        return this.parseSimpleMessage(rawContent);
      case RtContentStructure.SINGLE_LINE_ITEMS:
        return rawContent
          .split('\n')
          .filter((line) => this.isNotEmptyLineOrComment(line))
          .map((item) => this.parseSingleLineItem(item));
      case RtContentStructure.MULTILINE_ITEMS:
        return rawContent.split('\n--\n').map((item) => this.parseComplexItem(item));
      default:
        return [];
    }
  }

  private parseSimpleMessage(content: string): RtItem {
    const message: string = _.head(content.match(/\S.*/)) || '';
    return { message };
  }

  private isNotEmptyLineOrComment(line: string): boolean {
    return line !== '' && !line.startsWith('#');
  }

  private parseSingleLineItem(item: string): RtItem {
    const [id, value] = this.getKeyValuePair(item);

    return {
      id,
      value,
    };
  }

  private parseComplexItem(item: string): RtItem {
    const entities = item.match(/\S.*?(?=(\n\S|$))/gs) || [];

    return entities.reduce((acc: RtItem, entity: string) => {
      if (!entity.startsWith('#')) {
        const [key, rawValue] = this.getKeyValuePair(entity);
        const value = rawValue.includes('\n') ? rawValue.split('\n').map((l) => l.trim()) : rawValue;

        acc[_.camelCase(key)] = value;
      }
      return acc;
    }, {});
  }

  private getKeyValuePair(line: string): [string, string] {
    const firstColon = line.indexOf(':');
    const key = line.slice(0, firstColon);
    const value = line.slice(firstColon + 1).trim();
    return [key, value];
  }
}
