import { Routes } from '@angular/router';
import { AuthGuard, PermissionGuard } from '@ltic/ngx-core/auth';
import { LayoutComponent } from 'src/app/modules/layout/layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {
  faBox,
  faCloudSunRain,
  faExclamationTriangle,
  faHome,
  faPlane,
  faHistory,
  faStarHalfAlt,
  faSyringe,
  faChartArea,
  faHSquare,
  faProjectDiagram,
  faRandom,
} from '@fortawesome/free-solid-svg-icons';
import { getEnvironment } from '../environment';
import { EquipmentStorageConfig } from './equipment-shortages/equipment-shortages.module';
import { TycheRedirectGuard } from '../services/tyche-redirect-guard';
import { EmptyRouteComponent } from '../components/empty-route/empty-route.component';

export const PagesRoutes: Routes = [
  {
    path: 'app',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        component: DashboardComponent,
        data: {
          sidebar: {
            title: 'Home',
            url: '/app/home',
            icon: faHome,
          },
        },
      },
      {
        path: 'lra',
        data: {
          sidebar: {
            title: 'NEPA',
            url: '/app/lra',
            icon: faStarHalfAlt,
            requires: 'kn:lra',
          },
        },
        loadChildren: () => import('../modules/lra/lra.module').then((m) => m.LraModule),
      },
      {
        path: 'disruption',
        data: {
          sidebar: {
            title: 'KN Disruptions',
            url: '/app/disruption',
            icon: faExclamationTriangle,
            requires: 'kn:disruption:disruption:app',
          },
          basePermission: 'kn:disruption:disruption',
          tenantIds: ['disruptions', 'newsevent'],
          dataApiUrl: getEnvironment().diss.apiUrl,
          s3SignerUrl: getEnvironment().diss.s3signer,
        },
        loadChildren: () => import('../modules/diss/diss.module').then((m) => m.DissModule),
      },
      {
        path: 'disruptions',
        canActivate: [PermissionGuard],
        data: {
          sidebar: {
            title: 'KN Disruptions (new)',
            url: '/app/disruptions',
            icon: faExclamationTriangle,
            requires: 'kn:disruptions:disruptions:app',
          },
          requires: 'kn:disruptions:disruptions:app',
          basePermission: 'kn:disruptions:disruptions',
          tenantIds: ['disruptions', 'newsevent']
        },
        loadChildren: () => import('../modules/disruptions/disruptions.module').then((m) => m.DisruptionsModule),
      },
      {
        path: 'se_disruption',
        data: {
          sidebar: {
            title: 'SE Disruptions',
            url: '/app/se_disruption',
            icon: faExclamationTriangle,
            requires: 'kn:disruption:se_disruption:app',
          },
          basePermission: 'kn:disruption:se_disruption',
          tenantIds: ['diss', 'se_source'],
          dataApiUrl: getEnvironment().diss.apiUrl,
          s3SignerUrl: getEnvironment().diss.s3signer,
        },
        loadChildren: () => import('../modules/diss/diss.module').then((m) => m.DissModule),
      },
      {
        path: 'il_disruption',
        data: {
          sidebar: {
            title: 'IL Disruptions',
            url: '/app/il_disruption',
            icon: faExclamationTriangle,
            requires: 'kn:disruption:il:app',
          },
          basePermission: 'kn:disruption:il',
          tenantIds: ['il', 'il_source'],
          dataApiUrl: getEnvironment().diss.apiUrl,
          s3SignerUrl: getEnvironment().diss.s3signer,
        },
        loadChildren: () => import('../modules/diss/diss.module').then((m) => m.DissModule),
      },
      {
        path: 'hypercare_disruption',
        data: {
          sidebar: {
            title: 'HyperCare Disruptions',
            url: '/app/hypercare_disruption',
            icon: faExclamationTriangle,
            requires: 'kn:disruption:hypercare:app',
          },
          basePermission: 'kn:disruption:hypercare',
          tenantIds: ['hypercare', 'hypercare_source'],
          dataApiUrl: getEnvironment().diss.apiUrl,
          s3SignerUrl: getEnvironment().diss.s3signer,
        },
        loadChildren: () => import('../modules/diss/diss.module').then((m) => m.DissModule),
      },
      {
        path: 'capa_analysis',
        data: {
          sidebar: {
            title: 'Capa Disruptions',
            url: '/app/capa_analysis',
            icon: faExclamationTriangle,
            requires: 'kn:disruption:aircapa:app',
          },
          basePermission: 'kn:disruption:aircapa',
          tenantIds: ['aircapa'],
          dataApiUrl: getEnvironment().diss.apiUrl,
          s3SignerUrl: getEnvironment().diss.s3signer,
        },
        loadChildren: () => import('../modules/diss/diss.module').then((m) => m.DissModule),
      },
      {
        path: 'covaxmon',
        data: {
          sidebar: {
            title: 'CoVaxMon',
            url: '/app/covaxmon',
            icon: faSyringe,
            requires: 'kn:covaxmon',
          },
        },
        loadChildren: () => import('../modules/covaxmon/covaxmon.module').then((m) => m.CoVaxMonModule),
      },
      {
        path: 'eq_shortages',
        data: {
          sidebar: {
            title: 'Equipment shortages',
            url: '/app/eq_shortages',
            icon: faBox,
            requires: 'kn:eq_shortages',
          },
          data: <EquipmentStorageConfig>{
            layerId: 'eq_sea_carrier_equipment_shortage',
            groupingRelation: 'carrier_id',
            locationRelation: 'port_id',
          },
        },
        loadChildren: () =>
          import('./equipment-shortages/equipment-shortages.module').then((m) => m.EquipmentShortagesModule),
      },
      {
        path: 'weather_map',
        data: {
          sidebar: {
            title: 'Weather Map',
            url: '/app/weather_map',
            icon: faCloudSunRain,
            requires: 'kn:weather_map',
            notes: 'beta',
          },
        },
        loadChildren: () => import('../modules/weather-map/weather-map.module').then((m) => m.WeatherMapModule),
      },
      { path: 'capawiz', redirectTo: 'datawiz' },
      {
        path: 'datawiz',
        data: {
          sidebar: {
            title: 'DataWiz',
            url: '/app/datawiz',
            icon: faChartArea,
            requires: 'kn:capawiz',
          },
        },
        loadChildren: () => import('../modules/capawiz/capawiz.module').then((m) => m.CapaWizModule),
      },
      { path: 'flight_schedules', redirectTo: 'air_explorer' },
      {
        path: 'air_explorer',
        data: {
          sidebar: {
            title: 'AirExplorer',
            url: '/app/air_explorer',
            icon: faPlane,
            requires: 'kn:flight_schedules',
            notes: 'beta',
          },
        },
        loadChildren: () =>
          import('../modules/flight-schedules/flight-schedules.module').then((m) => m.FlightSchedulesModule),
      },
      {
        path: 'transit_time_scheduler',
        data: {
          sidebar: {
            title: 'Transit Time Scheduler',
            url: '/app/transit_time_scheduler',
            icon: faHistory,
            requires: 'kn:transit_time_scheduler',
            notes: 'alpha',
          },
        },
        loadChildren: () =>
          import('../modules/transit-time-scheduler/transit-time-scheduler.module').then(
            (m) => m.TransitTimeSchedulerModule,
          ),
      },
      {
        path: 'hypercare',
        data: {
          sidebar: {
            title: 'HyperCare Cockpit',
            url: '/app/hypercare',
            icon: faHSquare,
            requires: 'kn:hypercare',
          },
        },
        loadChildren: () =>
          import('../modules/hypercare-cockpit/hypercare-cockpit.module').then((m) => m.HypercareCockpitModule),
      },
      {
        path: 'flight_matrix',
        data: {
          sidebar: {
            title: 'Flight Matrix Generator',
            url: '/app/flight_matrix',
            icon: faProjectDiagram,
            requires: 'kn:flight_matrix',
            notes: 'beta',
          },
        },
        loadChildren: () =>
          import('../modules/flight-matrix/flight-matrix.module').then((m) => m.FlightMatrixModule),
      },
      {
        path: 'blendare',
        canActivate: [TycheRedirectGuard],
        data: {
          tycheWebRootPath: 'blendare',
          sidebar: {
            title: 'ARE Blending',
            url: '/app/blendare',
            icon: faRandom,
            requires: 'kn:blendare',
            notes: 'alpha',
          },
        },
        component: EmptyRouteComponent,
      },
    ],
  },
];
